import React, { useState } from "react";
import { Button, Col, Input, Row } from "antd";

import { SearchOutlined } from "@ant-design/icons";

import "./../SearchStudent/search-student.scss";

const SearchCommon = ({
  label,
  isRequired = false,
  enterButton,
  onSearch,
  ...rest
}) => {
  const [value, setValue] = useState("");

  const handleChangeValueSearch = (event) => {
    setValue(event.target.value);
  };
  return (
    <Row className="search">
      <Col>
        <Input
          onChange={(e) => handleChangeValueSearch(e)}
          {...rest}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onSearch(value);
            }
          }}
        />
      </Col>
      <Col>
        <Button
          className="search__button"
          iconPosition="end"
          icon={<SearchOutlined />}
          onClick={() => onSearch(value)}
        >
          {enterButton}
        </Button>
      </Col>
    </Row>
  );
};

export default SearchCommon;
