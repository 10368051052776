import React, { useState, useEffect, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import { Form, Input } from "antd";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { ReactSVG } from "react-svg";
import { Select, Menu } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import SubMenu from "antd/lib/menu/SubMenu";
import Cookies from "js-cookie";

import { translations } from "i18n-js";

import {
  URL_API_HISTORY,
  URL_API_ACTIVITY_HOME,
  URL_API_BRANCH_IN_AREA,
  URL_FACEBOOK,
  URL_YOUTUBE,
} from "./../utils/constants";
import { getTakenData } from "./../utils/service";
import { stringToSlug, changeLanguage } from "./../utils/functions";
import { setScrollPage } from "./../actions/index";

import Logo from "../assets/images/logo2.svg";
import IconTranslator from "../assets/images/icons/translator-icon.svg";
import SearchIcon from "../assets/images/icons/search-icon.svg";
import YoutubeIcon from "../assets/images/icons/youtube-icon.svg";
import FacebookIcon from "../assets/images/icons/facebook-icon.svg";
import RightIcon from "../assets/images/icons/right-icon.svg";
import FacebookWhite from "../assets/images/icons/facebook-white.svg";
import YoutubeWhite from "../assets/images/icons/youtube-white.svg";
import AppStore from "../assets/images/App_Store_Menu.svg";
import GooglePlay from "../assets/images/Google_Play_Menu.svg";

import "../assets/styles/header/header_v2.scss";

const subMenuLibrary = [
  {
    id: 1,
    name: "Hình ảnh",
  },
  {
    id: 2,
    name: "Video",
  },
  {
    id: 3,
    name: "Sách",
  },
  {
    id: 4,
    name: "Tư liệu tham khảo",
  },
];
const subMenuNews = [
  {
    id: 3,
    name: "Những Suy Ngẫm Từ Thầy Nguyễn Văn Dũng",
  },
  {
    id: 4,
    name: "Hành Trình Huyền Đai",
  },
  {
    id: 5,
    name: "Góc Nhìn Của Môn Sinh",
  },
  {
    id: 1,
    name: "Bài Viết Khác",
  },
];

// const languageOptionsMobile = [
//   {
//     value: "vn",
//     label: "VN",
//   },
//   {
//     value: "us",
//     label: "EN",
//   },
//   {
//     value: "jp",
//     label: "JP",
//   },
// ];

// const languageOptions = [
//   { id: 1, name: "Tiếng Việt", sign: "VI", lang: "vi" },
//   { id: 2, name: "Tiếng Anh", sign: "EN", lang: "en" },
//   // { id: 3, name: "Tiếng Trung", sign: "CN" },
//   { id: 4, name: "Tiếng Nhật", sign: "JP", lang: "ja" },
// ];

const appStoreMenu = [
  {
    id: 2,
    image: AppStore,
    url: "https://apps.apple.com/vn/app/apple-store/id1526775987?l=vi",
  },
  {
    id: 1,
    image: GooglePlay,
    url: "https://play.google.com/store/apps/details?id=com.nghiadung.karate",
  },
];

const siderbarOptions = [
  { key: 1, label: "Trang chủ", href: "/" },
  { key: 2, label: "Lịch sử", href: "/history" },
  { key: 3, label: "Phân đường", href: "/branchsinareas" },
  { key: 4, label: "Kỹ thuật", href: "/group-techniques" },
  { key: 5, label: "Hoạt động", href: "/activity-new" },
  { key: 6, label: "Thư viện", href: "/document" },
  { key: 7, label: "Bài viết", href: "/web-news-by?3" },
  { key: 8, label: "Tra cứu", href: "/student-search" },
];

const displaySize = window.innerWidth <= 768;

const socicalDesktopOptions = [
  {
    key: "facebook",
    icon: FacebookIcon,
    href: URL_FACEBOOK,
  },
  {
    key: "youtube",
    icon: YoutubeIcon,
    href: URL_YOUTUBE,
  },
];

const socicalMobileOptions = [
  {
    key: "facebook",
    icon: FacebookWhite,
    href: URL_FACEBOOK,
  },
  {
    key: "youtube",
    icon: YoutubeWhite,
    href: URL_YOUTUBE,
  },
];

const Header = ({ listRoute }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const isShowHeader = useSelector((state) => state?.handleShowHeader?.show);

  const [formHome] = Form.useForm();
  const searchQuery = new URLSearchParams(location.search).get("search");

  const [openMenuMobie, setOpenMenuMobie] = useState(false);
  // const [language, setLanguage] = useState(languageOptions[0].lang);
  const [subMenuOptions, setSubMenuOptions] = useState([]);

  const [currentMenu, setCurrentMenu] = useState();

  const [isTransparent, setIsTransparent] = useState(true);
  const [isShowLogo, setIsShowLogo] = useState(false);

  const [dataHistory, setDataHistory] = useState([]);
  const [dataActivity, setDataActivity] = useState([]);
  const [isShowSearch, setIsShowSearch] = useState(false);

  useEffect(() => {
    if (!searchQuery) {
      formHome.setFieldsValue({
        search: "",
      });
    }
  }, [searchQuery]);

  useEffect(() => {
    getTakenData(URL_API_BRANCH_IN_AREA).then((res) => {
      setSubMenuOptions(res?.data);
    });
  }, []);

  const handleBranchs = (id) => {
    history.push(`/branchsinareas/${id}`);
  };

  const handleLibrary = (id) => {
    history.push(`/document?${id}`);
  };

  const handleNews = (id) => {
    history.push(`/web-news-by?${id}`);
  };

  const handleClickMenuMobile = (id) => {
    if (id) {
      history.push(id);
      setOpenMenuMobie(!openMenuMobie);
    }
  };

  const siderbarOptionsMobile = [
    { key: 1, label: "Trang chủ", href: "/" },
    { key: 2, label: "Lịch sử", href: "/history" },
    {
      key: 3,
      label: "Phân đường",
      children: subMenuOptions?.map((item) => ({
        key: `${item?.id}`,
        label: item?.name,
        href: `/branchsinareas/${item?.id}`,
      })),
    },
    { key: 4, label: "Kỹ thuật", href: "/group-techniques" },
    { key: 5, label: "Hoạt động", href: "/activity-new" },
    {
      key: 6,
      label: "Thư viện",
      children: subMenuLibrary?.map((item) => ({
        key: `${item?.id}`,
        label: item?.name,
        href: `/document?${item?.id}`,
      })),
    },
    {
      key: 7,
      label: "Bài viết",
      children: subMenuNews?.map((item) => ({
        key: `${item?.id}`,
        label: item?.name,
        href: `/web-news-by?${item?.id}`,
      })),
    },
    { key: 8, label: "Tra cứu", href: "/student-search" },
  ];

  // const handleChangeLanguage = (lang) => {
  //   changeLanguage(lang);
  //   setLanguage(lang);
  // };

  // const changeLanguage = (e) => {
  //   const selected = e.target.value;
  // };
  // const menu = () => (
  //   <Menu className="menu_language" onClick={changeLanguage}>
  //     {languages &&
  //       languages.map((lang) => {
  //         if (lang.sign !== language)
  //           return <Menu.Item key={lang.sign}>{lang.name}</Menu.Item>;
  //         else return null;
  //       })}
  //   </Menu>
  // );
  const handleMenuMobie = () => {
    setOpenMenuMobie(!openMenuMobie);
  };

  const handleRedirect = (id) => {
    if (openMenuMobie) {
      setOpenMenuMobie(false);
    }
    history.push("/");
    setTimeout(function () {
      dispatch(setScrollPage(id));
    }, 500);
  };

  const handleRedirectSearch = () => {
    if (openMenuMobie) {
      setOpenMenuMobie(false);
    }
    history.push("/search");
  };

  const classNameToggleMenu = openMenuMobie
    ? "toggle-menu open"
    : "toggle-menu";

  const handleScroll = () => {
    let number =
      window.pageXOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    if (location.pathname === "/") {
      if (number < 40) {
        setIsTransparent(true);
        setIsShowLogo(false);
      } else {
        if (number > 140) {
          setIsShowLogo(true);
          setIsTransparent(false);
        }
      }
    } else {
      if (number < 40) {
        setIsTransparent(true);
        setIsShowLogo(true);
      } else {
        if (number > 140) {
          setIsShowLogo(true);
          setIsTransparent(false);
        }
      }
    }
  };

  useEffect(() => {
    if (location.pathname === "/") {
      setIsShowLogo(false);
      setIsTransparent(true);
    } else {
      setIsShowLogo(true);
      setIsTransparent(true);
    }
    window.addEventListener("scroll", handleScroll);
    return function cleanupListener() {
      window.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const fetchDataHistory = () => {
    try {
      getTakenData(URL_API_HISTORY).then((res) => {
        const dataTmp = res?.data?.map((item) => {
          return {
            ...item,
            slugUrl: stringToSlug(item.title),
          };
        });
        setDataHistory(dataTmp);
      });
    } catch (error) {
      setDataHistory([]);
    }
  };

  const getDataActivity = () => {
    try {
      getTakenData(URL_API_ACTIVITY_HOME).then((res) => {
        const dataTmp = res?.data?.map((item) => {
          return {
            ...item,
            slugUrl: stringToSlug(item.title),
          };
        });
        setDataActivity(dataTmp);
      });
    } catch (error) {
      setDataActivity([]);
    }
  };

  // Init event click outside menu
  const clickOutSide = (e) => {
    if (
      !e.target.closest(".nav-menu") &&
      !e.target.closest(".feature_header--content-btnToggle")
    ) {
      setOpenMenuMobie(false);
    }
  };

  useEffect(() => {
    fetchDataHistory();
    getDataActivity();

    // const gglang = Cookies.get("googtrans");
    // setLanguage(gglang ? gglang.replace("/vi/", "") : "vi");

    // Event click outside menu
    document.addEventListener("click", clickOutSide);
    return function () {
      document.removeEventListener("click", clickOutSide);
    };
  }, []);

  //languages
  // function onChange(value) {
  //   localStorage.setItem("to", value);
  //   window.location.reload();
  // }

  // function onSearch(val) {
  // }

  const toggleSearch = () => {
    formHome.setFieldsValue({
      search: "",
    });

    history.push("/");
    setIsShowSearch((prev) => !prev);
  };

  const handleSearch = (e) => {
    const value = e?.target?.value;

    const enCodeValue = encodeURIComponent(value);

    if (value) {
      history.push(`/detail-search?search=${enCodeValue}`);
      setOpenMenuMobie(!openMenuMobie);
    }
  };

  const handleBlur = (e) => {
    setCurrentMenu(e);
  };

  const renderSubmenu = useCallback(() => {
    switch (currentMenu) {
      case 3:
        return (
          <div className="header-wrap-menu__submenus">
            <p className="submenu-title">{t("header.phanDuong")}</p>

            <div className="submenu-wrap">
              {subMenuOptions?.map((submenu) => (
                <div
                  className="submenu-wrap-content"
                  key={submenu.id}
                  id={submenu.id}
                  onClick={() => {
                    handleBranchs(submenu?.id);
                    setCurrentMenu(false);
                  }}
                >
                  <p>
                    {submenu?.name}
                    <ReactSVG src={RightIcon} />
                  </p>
                </div>
              ))}
            </div>
          </div>
        );
      case 6:
        return (
          <div className="header-wrap-menu__submenus">
            <p className="submenu-title">{t("header.library")}</p>

            <div className="submenu-wrap">
              {subMenuLibrary?.map((submenu) => (
                <div
                  className="submenu-wrap-content"
                  key={submenu.id}
                  id={submenu.id}
                  onClick={() => {
                    handleLibrary(submenu?.id);
                    setCurrentMenu(false);
                  }}
                >
                  <p>
                    {submenu?.name}
                    <ReactSVG src={RightIcon} />
                  </p>
                </div>
              ))}
            </div>
          </div>
        );

      case 7:
        return (
          <div className="header-wrap-menu__submenus">
            <p className="submenu-title">{t("header.info")}</p>

            <div className="submenu-wrap__information">
              {subMenuNews?.map((submenu) => (
                <div
                  className="submenu-wrap__information-content"
                  key={submenu.id}
                  id={submenu.id}
                  onClick={() => {
                    handleNews(submenu?.id);
                    setCurrentMenu(false);
                  }}
                >
                  <p>
                    {submenu?.name}
                    <ReactSVG src={RightIcon} />
                  </p>
                </div>
              ))}
            </div>
          </div>
        );

      default:
        break;
    }
  }, [currentMenu]);

  useEffect(() => {
    let prevScrollpos = window.scrollY;
    if (isShowHeader) {
      document.getElementById("header-destop").style.display = "none";
    } else {
      document.getElementById("header-destop").style.display = "block";
      window.onscroll = function () {
        const currentScrollPos = window.scrollY;
        if (prevScrollpos > currentScrollPos || currentScrollPos <= 150) {
          document.getElementById("hidden-sticky-top").style.top = "0";
          document.getElementById("header-destop").style.top = "0";
        } else {
          document.getElementById("hidden-sticky-top").style.top = "-200px";
          document.getElementById("header-destop").style.top = "-200px";
        }
        prevScrollpos = currentScrollPos;
      };
    }
  }, [isShowHeader]);

  const handleRedirectSocical = (socical) => {
    const newWindow = window.open(socical);
    if (newWindow) newWindow.opener = null;
  };

  return (
    // <Translator
    //   from="vi"
    //   to={localStorage.getItem("to")}
    //   googleApiKey="AIzaSyCMV_XCGZjgDZ-sEB5zZir1fxAluZMBelc"
    // >
    <Form form={formHome} className="sticky-top" id="hidden-sticky-top">
      <div className="feature_header" id={displaySize ? "header-destop" : ""}>
        <div className="header-wrap">
          <div
            className={`${
              openMenuMobie
                ? "feature_header--content-btnToggle d-md-block open"
                : "feature_header--content-btnToggle d-md-block"
            } `}
            onClick={handleMenuMobie}
          >
            <div className={classNameToggleMenu} title="Menu">
              <div className="toggle-menu_line-1 fadeIn"></div>
              <div className="toggle-menu_line-2 fadeIn"></div>
              <div className="toggle-menu_line-3 fadeIn"></div>
            </div>
          </div>

          <div className="header-wrap__logo">
            <img
              src={Logo}
              alt="nghia-dung"
              onClick={() => {
                history.push("/");
              }}
            />
          </div>

          <nav className={`nav-menu ${openMenuMobie ? "visible" : ""}`}>
            <div className="nav-menu-body">
              {/* <ul>
                {dataHistory?.[0] && (
                  <li
                    onClick={() =>
                      handleRedirect(`history-${dataHistory?.[0]?.id}`)
                    }
                  >
                    <Link to='' className='content-custom'>
                      {t("menu.history")}
                    </Link>
                  </li>
                )}
                <li onClick={() => handleRedirect("section-club")}>
                  <Link to='' className='content-custom'>
                    {t("menu.club")}
                  </Link>
                </li>
                <li onClick={() => handleRedirect("section-skill")}>
                  <Link to='' className='content-custom'>
                    {t("menu.skill")}
                  </Link>
                </li>
                <li className='has-sub-menu'>
                  <input
                    type='radio'
                    className='subMenu-dropdown d-none'
                    name='subMenu-dropdown'
                    readOnly
                  />
                  <label
                    onClick={() => handleRedirect("section-activity")}
                    className='d-flex align-items-between w-100 mb-0'
                  >
                    <p className='m-0' style={{ flex: 1 }}>
                      <Link to='' className='content-custom'>
                        {t("menu.activity")}
                      </Link>
                    </p>
                  </label>
                  <ul className='subMenu'>
                    {dataActivity?.map((item, index) => {
                      return (
                        <li
                          key={index}
                          onClick={() => handleRedirect(`activity-${item.id}`)}
                        >
                          {item.id === 7 && (
                            <Link to='' className='content-custom'>
                              {t("activity.schedule")}
                            </Link>
                          )}
                          {item.id === 8 && (
                            <Link to='' className='content-custom'>
                              {t("activity.competition")}
                            </Link>
                          )}
                          {item.id === 9 && (
                            <Link to='' className='content-custom'>
                              {t("activity.charity")}
                            </Link>
                          )}
                          {item.id === 10 && (
                            <Link to='' className='content-custom'>
                              {t("activity.outdoor")}
                            </Link>
                          )}
                          {item.id === 11 && (
                            <Link to='' className='content-custom'>
                              {t("activity.others")}
                            </Link>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                </li>
                <li onClick={() => handleRedirect("section-library")}>
                  <Link to='' className='content-custom'>
                    {t("menu.library")}
                  </Link>
                </li>
                <li onClick={() => handleRedirect("section-information")}>
                  <Link to='' className='content-custom'>
                    {t("menu.information")}
                  </Link>
                </li>
                <li onClick={() => handleRedirectSearch("")}>
                  <Link to='' className='content-custom'>
                    {t("menu.search")}
                  </Link>
                </li>
              </ul> */}
              <div className="nav-menu-body__logo">
                <img
                  src={Logo}
                  alt="nghia-dung"
                  onClick={() => {
                    history.push("/");
                  }}
                />
              </div>
              {siderbarOptionsMobile?.length !== 0 && (
                <Menu mode="inline">
                  {siderbarOptionsMobile?.map((menu) => {
                    if (menu?.children?.length > 0) {
                      return (
                        <SubMenu
                          key={menu?.key}
                          title={
                            <span className="submenu-mobile">
                              {menu?.label}
                            </span>
                          }
                        >
                          {menu?.children?.map((submenu) => (
                            <Menu.Item
                              key={submenu?.key}
                              onClick={() =>
                                handleClickMenuMobile(submenu?.href)
                              }
                            >
                              {submenu?.label}
                            </Menu.Item>
                          ))}
                        </SubMenu>
                      );
                    } else {
                      return (
                        <Menu.Item
                          key={menu?.key}
                          onClick={() => handleClickMenuMobile(menu?.href)}
                        >
                          {menu?.label}
                        </Menu.Item>
                      );
                    }
                  })}
                </Menu>
              )}
              <div className="nav-menu-body__action">
                <div className="search-wrap">
                  <Form.Item name="search">
                    <Input
                      prefix={
                        <img
                          src={SearchIcon}
                          style={{ width: "20px", height: "20px" }}
                          alt="Search Icon"
                        />
                      }
                      placeholder="Tìm kiếm ...."
                      onPressEnter={handleSearch}
                    />
                  </Form.Item>
                </div>

                {/* <div className="translator-wrap">
                  <img
                    src={IconTranslator}
                    alt="translator icon"
                    style={{ width: "24px", height: "24px" }}
                  />

                  {languageOptions?.map((item) => (
                    <p
                      key={item?.id}
                      translate="no"
                      className={`${
                        item?.lang === language ? "active" : ""
                      } translator`}
                      onClick={() => handleChangeLanguage(item?.lang)}
                    >
                      {item?.sign}
                    </p>
                  ))}
                </div> */}

                {socicalMobileOptions?.length > 0 && (
                  <div className="socical-wrap">
                    {socicalMobileOptions?.map((socical) => (
                      <ReactSVG
                        key={socical?.key}
                        src={socical?.icon}
                        onClick={() => handleRedirectSocical(socical?.href)}
                      />
                    ))}
                  </div>
                )}
              </div>
              <div className="nav-menu-body__contact">{t("menu.contact")}</div>
              {/* {siderbarOptions?.map((menu) => (
                <div className='nav-menu-body__menu'>
                  <p
                    className={`title ${
                      currentMenu === menu?.key && "menu-active"
                    }`}
                    onMouseEnter={() => handleBlurMenuMobile(menu?.key)}
                    onClick={() => history.push(`${menu?.href}`)}
                  >
                    {menu?.label}
                  </p>
                </div>
              ))} */}
            </div>
          </nav>
        </div>
      </div>
      <div className="header-wrap" id={!displaySize ? "header-destop" : ""}>
        <div className="header-wrap-action">
          <div className="header-wrap-action__logo">
            <img
              src={Logo}
              alt="nghia-dung"
              onClick={() => {
                history.push("/");
              }}
            />
          </div>

          <div className="header-wrap-action__search">
            <div className="search-wrap">
              <Form.Item name="search">
                <Input
                  prefix={
                    <img
                      src={SearchIcon}
                      style={{ width: "20px", height: "20px" }}
                      alt="Search Icon"
                    />
                  }
                  placeholder="Tìm kiếm ...."
                  onPressEnter={handleSearch}
                  allowClear
                />
              </Form.Item>
            </div>

            {/* <div className="translator-wrap">
              <div id="google_translate_element"></div>

              <img
                src={IconTranslator}
                alt="translator icon"
                style={{ width: "24px", height: "24px" }}
              />

              {languageOptions?.map((item) => (
                <p
                  key={item?.id}
                  translate="no"
                  className={`${
                    item?.lang === language ? "active" : ""
                  } translator`}
                  onClick={() => handleChangeLanguage(item?.lang)}
                >
                  {item?.sign}
                </p>
              ))}
            </div> */}

            {socicalDesktopOptions?.length > 0 && (
              <div className="socical-wrap">
                {socicalDesktopOptions?.map((socical) => (
                  <ReactSVG
                    key={socical?.key}
                    src={socical?.icon}
                    onClick={() => handleRedirectSocical(socical?.href)}
                  />
                ))}
              </div>
            )}
          </div>
          <div
            className="header-wrap-action__contact"
            onClick={() => {
              history.push("/lien-he");
            }}
          >
            {t("menu.contact")}
          </div>
        </div>

        <div
          className="header-wrap-menu"
          onMouseLeave={() => setCurrentMenu(false)}
        >
          <div className="header-wrap-menu__content">
            {siderbarOptions?.map((menu) => (
              <p
                key={menu?.key}
                className={`title ${
                  currentMenu === menu?.key && "menu-active"
                }`}
                onMouseEnter={() => handleBlur(menu?.key)}
                onClick={() => history.push(`${menu?.href}`)}
              >
                {menu?.label}
              </p>
            ))}
          </div>
          {renderSubmenu()}
        </div>
      </div>
    </Form>

    // </Translator>
  );
};

export default Header;
